.links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.menuItem{
    margin-bottom: 40px;
}
.menuItem a{
    color: white;
    text-decoration: none;
    font-size: 5vw;
    border-left: 23px solid transparent;
    padding-left: 23px;
}
.menuItem .active{
    border-left: 23px solid #35AAF9
}

@media screen and (max-width: 468px){
    .links{
        margin-top: 50px;
    }
}