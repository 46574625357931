html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body{
  margin: 0;
  font-family: 'Gilroy', sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  opacity: 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000426;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 0;
}

.slick-slide{
  outline: none !important;
  height: 0;
}

.slick-active {
  height: max-content;
}
.slick-active button{
  background-color: #35AAF9 !important;
}


@media screen and (max-width: 1279px){
  .carousel-track{
    height: 402px !important;
  }
  .MuiPopover-root{
    z-index: 11700 !important;
  }
}
@media screen and (max-width: 1000px){
  .carousel-track{
    height: 522px !important;
  }
}
@media screen and (max-width: 756px){
  .carousel-track{
    height: 622px !important;
  }

}

@media screen and (max-width: 468px){
  .carousel-initialized{
    padding: 0 !important;
  }
  .carousel-item{
    z-index: 2;
  }
}