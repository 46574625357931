.main{
    width: 100%;
}
.main a{
    text-decoration: none;
}
.header{
    width: 100%;
    height: 60px;
    background-color: #000426;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy-Light';
    font-weight: 300;
    color: white;
    font-size: 16px;
}
.menu{
    width: 100%;
    height: 107px;
    background-color: white;
    display: flex;
    justify-content: center;
    font-family: 'GothamPro-Bold';
    font-size: 18px;
}
.container{
    /* width: 1440px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
}
.block{
    display: flex;
    align-items: center;
}
.block img{
    margin-right: 15px;
}
.block a{
    color: white;
}
.block a:hover{
    text-decoration: underline;
}
.phone{
    margin-right: 20px;
}
.links{
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: space-between;
}
.links a{
    color: black;
    transition-duration: .3s;
}
.links a:hover{
    transform: scale(1.02);
}
.mobileHeader{
    display: none;
}
.mobileMenu{
    display: none;
}

.insta{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    margin-right: 25px;
    color: black;
    text-transform: uppercase;
}
.insta img{
    width: 25px;
    height: auto;
    margin-bottom: 5px;
}
.insta svg{
    margin-bottom: 5px;
}

@media screen and (max-width: 1280px){
    .container{
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 1279px){
    .header{
        display: none;
    }
    .menu{
        display: none;
    }
    .mobileHeader{
        display: flex;
        height: 88px;
        align-items: center;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        background-color: #000426;
        box-sizing: border-box;
    }
    .mobileHeader .insta{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        margin-right: 25px;
        color: white;
        text-transform: uppercase;
    }
    .mobileHeader .insta img{
        width: 25px;
        margin-bottom: 5px;
        margin-right: 0;
    }
    .insta svg{
        margin-bottom: 5px;
    }
    .mobileHeader a{
        text-align: center;
        text-decoration: none;
        color: white;
        font-family: 'GothamPro-Bold';
        font-size: 22px;
        display: block;
    }
    .mobileHeader a img{
        width: 80%;
    }
    .mobileMenu{
        display: flex;
        height: 52px;
        background-color: white;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
    .mobileMenu a{
        color: black;
        font-family: 'Gilroy-Medium';
        font-size: 18px;
    }
    .phone{
        margin-left: 25px;
        margin-right: 25px;
    }
    .main{
        position: fixed;
        z-index: 20;
        top: 0;
        box-shadow: 0 0 29px -10px grey;
    }
}

@media screen and (max-width: 468px) {
    .mobileMenu a{
        font-size: 16px;
    }
}
@media screen and (max-width: 400px) {
    .mobileMenu a{
        font-size: 14px;
    }
}
@media screen and (max-width: 350px){
    .mobileMenu a{
        font-size: 13px;
    }
    .phone{
        margin-left: 10px;
        margin-right: 10px;
    }
}